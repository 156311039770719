$btnBg: darkblue;
$btnCol: white;

.App {
  text-align: center;

  .top {
    height: 40px;
    text-align: left;

    .button {
      display: inline-block;
      padding: 4px 8px;
      cursor: pointer;
    }
    .button.active {
      background: $btnBg;
      color: $btnCol;
    }

    .right {
      position: absolute;
      right: 10px;
      top: 10px;

      i {
        font-size: 1.3em;
        color: #999;
      }
    }
  }

  .center {
    height: 100px;
    margin-top: calc(50vh - 100px);

    .logo {
      font-size: 3em;
      font-weight: bold;
    }
  }

  .word {
    font-size: 6em;
    line-height: 50px;
  }

  .bottom {
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 100%;

    .button {
      background: $btnBg;
      color: $btnCol;
      padding: 10px 20px 15px;
      font-size: 2em;
      border-radius: 20px;
      cursor: pointer;
      margin: auto;
      width: 150px;
    }
  }

  .comp-settings {
    text-align: left;
    padding: 20px;
    
    .backButton {
      font-size: 2em;
      display: inline-block;
    }

    h1 {
      display: inline-block;
      margin-left: 20px;
    }

    .icon.done {
      color: #00a700;
    }

    .icon.now {
      color: #1717ff;
    }

    .group {
      margin-bottom: 10px;
      .title {
        font-weight: bold;
      }
      .subgroup {
        transition: height 1s;
        overflow: hidden;
        height: auto;
      }
      .right { 
        display: none;
      }
      .down {
        display: inline-block;
      }
    }

    .group.collapsed {
      .subgroup {
        height: 0;
      }
      .right { 
        display: inline-block;
      }
      .down {
        display: none;
      }
    }
  }
}

.App.horizontal {
  .bottom {
    bottom: calc(50vh - 40px);
    width: 50px;
    right: 120px;
    .button {
      transform: rotate(-90deg);
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}